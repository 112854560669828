<template>
  <div> <div class="containertoptop">
    <div class="containertop" v-if="centerimgtop">
     
        <div class="containerimg"> <img src="../../assets/index/show.png" class="img" alt=""> <p>线下支付可能勋在交易风险，请选择线上交易。</p></div> 
        <img src="../../assets/index/clear.png" class="cimg" alt="" @click="imgcenter">
    </div>
    </div>
    <el-container class="container">
      <el-aside width="200px"
        ><MessageBox
          :type="activeKey"
          :card="$refs['person_card']"
          :select="select"
          ref="messageBox"
      /></el-aside>
      <div class="backimg">
        <el-main width="600px">
          <Messages
            v-show="currselect != null"
            :type="activeKey"
            :broken="broken"
            :hideUserList="hideUserList"
            :showUserList="showUserList"
            ref="messageList"
            @show_add_member_modal="show_add_member_modal"
          />
        </el-main>
      </div>
    </el-container>
  </div>
</template>

<script>
import Vue from "vue";
import { Message } from "element-ui";
import MessageBox from "../../components/chat/index.vue";
import Messages from "../../components/chat/message.vue";
import dsd from "../../assets/home/message.png";
import imApi from "../../api/imApi";
import loginApi from "../../api/loginApi";
// import loginApi from "../../assets/index/show.png";
// import "./index.scss";
import { mapState, mapActions ,mapGetters} from "vuex";
export default {
  data() {
    return {
      centerimgtop:true,
      activedType: {
        contact: "",
      },
      currselect: null,
      contactRead: false,
      showSettingOptions: false,
      activeKey: "contact",
      selectedItem: "",
      showAddOptions: false,
      nowIsVideo: false,
      userName:
        localStorage.getItem("userInfo") &&
        JSON.parse(localStorage.getItem("userInfo")).userid,
      head_portraitImg: require("../../assets/headPortrait.jpeg"),
      collapsed: false,
      broken: false,
      current: ["contact"],
      nowClickID: "",
      showAlert: false,
    };
  },
  components: {
    MessageBox,
    Messages,
  },
  mounted() {
    // const userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"));
    // 		if(userInfo){
    // 			const userName = userInfo.userid;
    // 			const password = userInfo.password;
    // 			var options = {
    // 				user: userName,
    // 				pwd: password,
    // 				appKey: WebIM.config.appkey
    // 			};
    // 			WebIM.conn.open(options);
    // 		}
  },
  created() {
    this.checkLogin();
  },
  mounted() {},
  computed: {
      ...mapGetters({
      contact: "onGetContactUserList",
      msgList: "onGetCurrentChatObjMsg"
    }),
    userDetail() {
      return this.$store.state.home.userDetail;
    },
    chatList() {
      return this.$store.state.chat.msgList;
    },
    onSetCallStatus() {
      return this.$store.state.agora.callStatus;
    },
  },
  methods: {
    imgcenter(){
      this.centerimgtop = false
    },
    ...mapActions([
      "onLogout",
      "onGetFirendBlack",
      "initChatState",
      "updateConfr",
      "setCallStatus",
      "hangup",
      "cancelCall",
      "onGetContactUserList",
    ]),

    checkLogin() {
     
      let isLogin = localStorage.getItem("Login_data");
      if (!isLogin) {
        this.$router.push("/login");
        Message.error("请先登录");
      } else {
        loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
              Message.error("请先登录");
              localStorage.removeItem("Login_data");
              this.$router.push("/login");
            }
          }
        });
      }
      if (WebIM.isLogin) {
         this.onGetContactUserList()
      } else {
        imApi.getCurrUser("", (res) => {
          if (res.data.code == 200) {
            var options = {
              user: res.data.result.username,
              pwd: res.data.result.password,
              appKey: WebIM.config.appkey,
              apiUrl: WebIM.config.Host,
            };
            WebIM.conn.open(options);
          } else if (res.data.code == 401) {
            Message.error("请先进行个人认证");
            this.$router.push("/home/personalCenter");
          }
        });

         this.onGetContactUserList()
      }
      
    },
    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    show_add_member_modal() {
      this.$refs.addAvMembertModal.show();
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
    select(i) {
      this.currselect = i;
      this.$refs.messageList.select(i);
      //
      if (this.broken) {
        this.$data.collapsed = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.containertoptop{
   height: 30px;
   margin-top: 40px;
   line-height: 30px;
}
.containertop{
  // width: 30px;
  line-height: 30px;
  height: 30px;
display: flex;
justify-content: space-between;
padding: 2px 10px;
align-items: center;
background-color: rgb(253,249,237);
.containerimg{
  font-size: 12px;
  display: flex;
justify-content: space-between;
// padding: 2px 10px;
align-items: center;
 color: rgb(255,131,66);
}
.img{
  margin-right: 5px;
 
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.cimg{
  width: 10px;
  height: 10px;
}
}
.backimg {
  width: 600px;
  background-color: rgb(249 249 249);
  height: 500px;
  //  background:url("../../assets/home/message.png");
}
.el-container {
  border: 1px solid rgb(236, 236, 236);
  // margin-top: 40px;
  height: 560px;
}

.el-aside {
  overflow: hidden;
}
.el-main {
  overflow: hidden;
  padding: 0px 20px 0 0;
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "vant-icon";
  src: url(https://img.yzcdn.cn/vant/vant-icon-3a7dc2.woff2) format("woff2"),
    url(https://img.yzcdn.cn/vant/vant-icon-3a7dc2.woff) format("woff"),
    url(https://img.yzcdn.cn/vant/vant-icon-3a7dc2.ttf) format("truetype");
}
.layout-header {
  height: 100px !important;
  background: #434648 !important;
  padding: 0 !important;
  .header {
    height: 47px;
    display: flex;
    line-height: 50px;
    padding: 0 16px;
    justify-content: space-between;

    .setting {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      .head_portrait {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      .username {
        margin: 0 5px;
      }
    }
  }

  .navMenu-icon {
    font-size: 20px !important;
    margin-right: 5px !important;
  }

  .navMenu-text {
    font-size: 16px;
  }

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid #2bb907 !important;
    color: #05af4a !important;
  }
}

.van-icon {
  position: relative;
  font: 14px/1 "vant-icon";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.tip-style {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: 10px;
}

.contact {
  width: 100%;
  height: 100%;
  // overflow: hidden;

  .mask {
    width: 100%;
    position: fixed;
    cursor: default;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .header {
    width: 100%;
    height: 50px;
    background: #000;
    color: #fff;
    position: relative;
    text-align: left !important;

    .setting {
      display: inline-block;
      margin-left: 12px;
      margin-top: 12px;
    }

    .options {
      position: absolute;
      top: 40px;
      width: 130px;
      background-color: #fff;
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: left;
      background-color: #fff;
      border-radius: 2px;
      -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
      z-index: 99;

      .option {
        width: 100%;
        padding: 7px 8px;
        margin: 0;
        clear: both;
        font-size: 14px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.65);
        white-space: nowrap;
        cursor: pointer;
      }

      .option:hover {
        background-color: #dbdbdb;
      }
    }

    .options2 {
      right: 10px;
      text-align: center;
      z-index: 999;
    }

    .option2 {
      display: flex;
      justify-content: center;
    }

    .option2:hover {
      background-color: rgb(219, 219, 219);
    }

    .add-style {
      // display  : inline-block;
      margin-right: 12px;
      margin-top: 12px;
      float: right;
      cursor: pointer;
    }

    .username {
      position: relative;
      top: -5px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 50px);
    // overflow: hidden;

    .van-tabs,
    .van-tab__pane {
      height: 100%;
    }

    .van-tab {
      text-align: center;
      display: inline-block;
      width: 122px;
      line-height: 50px;
      padding: 0 20px;
      cursor: pointer;

      &::before {
        content: "\F02D";
        font: 20px/1 "vant-icon";
        vertical-align: text-top;
        margin-right: 5px;
      }
    }

    .van-tabs__content {
      height: calc(100% - 50px);
    }

    .van-tabs__nav {
      background: #000;
      color: #fff;
      text-align: left !important;
    }

    .van-tabs__line {
      background: #00ba6e;
      width: 122px !important;
      height: 2px;
      /* transform: translateX(0) translateX(0) !important; */
    }
  }
}
</style>

